<template>
  <sidebar-dropdown
    :title="t('ticket_sidebar.journey_runs')"
    collapse-key="journeysDropdown"
    data-test="journeys-dropdown-wrapper"
    @toggled="onToggled"
    @mounted="onToggled"
  >
    <div data-test="journeys-wrapper">
      <div class="flex w-full flex-col space-y-3">
        <div v-for="run in runs" :key="run.id" class="flex w-full items-center">
          <router-link
            :class="{
              'disabled pointer-events-none': !userHasPermission(),
              'font-bold underline': userHasPermission(),
            }"
            :to="`/workflows/runs/${run.id}`"
            class="mr-2 flex-1 truncate text-ellipsis"
          >
            {{ run.workflow.name }}
          </router-link>
          <div class="flex flex-shrink-0 items-center">
            <button
              v-if="userHasPermission() && ['paused', 'running'].includes(run.status)"
              class="border-grey mr-1.5 flex cursor-pointer items-center rounded-md border pl-1 pr-2 text-sm font-semibold hover:opacity-90"
              @click="abortJourney(run.id)"
            >
              <cross-circle-linear size="1rem" class="mr-0.5" />
              {{ t('ticket_sidebar.abort_journey_run') }}
            </button>
            <span
              class="flex-inline flex-shrink-0 rounded-full px-2 py-1 text-sm font-medium leading-none"
              :class="{
                'bg-success-200 text-success-900': run.status === 'completed',
                'bg-blue-lighter text-blue-darker': run.status === 'running',
                'bg-red-lighter text-red-darker': run.status === 'failed',
                'bg-grey-300 text-grey-800': ['escalated', 'paused'].includes(run.status),
                'bg-yellow text-yellow-darker': run.status === 'aborted',
              }"
              v-text="getStatusText(run.status)"
            />
          </div>
        </div>
        <div v-if="runs.length === 0" class="text-sm text-grey-700">
          <t-inline-banner type="default">
            <template #icon>
              <info-linear />
            </template>
            <p class="t-text-sm-emphasize m-0 p-0">{{ t('ticket_sidebar.no_journey_runs_found') }}</p>
          </t-inline-banner>
        </div>
      </div>
    </div>
  </sidebar-dropdown>
</template>

<script setup lang="ts">
import { CrossCircleLinear, InfoLinear } from '@trengo/trengo-icons';
import { ref, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';

import { abortJourneyRun, fetchWorkflowRunsForTicket } from '@/components/TicketSidebar/Api/requests';
import SidebarDropdown from '@/components/TicketSidebar/SidebarDropdown.vue';
import { PERMISSION } from '@/Configs/Constants';
import { useUserStore } from '@/store/pinia';

import type JourneyRun from '@/types/journeyRun';
import type Ticket from '@/types/tickets';

const { t } = useI18n();

const props = defineProps<{ ticket: Ticket }>();

const userStore = useUserStore();
const runs = ref<JourneyRun[]>([]);
const interval = ref<ReturnType<typeof setInterval> | null>(null);
const aborting = ref(false);

const fetchJourneyRuns = async (ticketId: number): Promise<void> => {
  if (aborting.value) {
    return;
  }
  runs.value = (await fetchWorkflowRunsForTicket(ticketId)).data?.list;
};

const startPolling = () => {
  interval.value = setInterval(() => fetchJourneyRuns(props.ticket.id), 20000);
};

const stopPolling = () => {
  if (interval.value) {
    clearInterval(interval.value);
    interval.value = null;
  }
};

const onToggled = (isOpen: boolean): void => {
  if (isOpen) {
    fetchJourneyRuns(props.ticket.id);
    startPolling();
  } else {
    stopPolling();
  }
};

const abortJourney = async (journeyRunId: JourneyRun['id']): Promise<void> => {
  if (!confirm(t('ticket_sidebar.abort_journey_run_confirmation'))) {
    return;
  }

  aborting.value = true;
  const runToUpdate = runs.value.find((run: { id: string }) => run.id === journeyRunId);
  if (runToUpdate) {
    runToUpdate.status = 'aborted';
  }

  await abortJourneyRun(journeyRunId);
  aborting.value = false;
};

const userHasPermission = () => {
  return userStore.hasPermission(PERMISSION.SETTINGS__AI_NAV__VIEW);
};

const getStatusText = (status: string): string => {
  if (status === 'completed') {
    return 'Completed';
  }
  if (status === 'running') {
    return 'Running';
  }
  if (status === 'failed') {
    return 'Failed';
  }
  if (status === 'paused') {
    return 'Waiting';
  }
  if (status === 'aborted') {
    return 'Aborted';
  }
  if (status === 'escalated') {
    return 'Escalated';
  }
  return '';
};

onUnmounted(() => {
  if (interval.value) {
    clearInterval(interval.value);
  }
});
</script>
