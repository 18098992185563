<template>
  <div class="mt-auto" :class="{ 'composer-dragenter': dragenter }">
    <div class="pos-relative flex h-full flex-col border-t-2 border-grey-200 lg:mx-8">
      <form class="note flex flex-1" @submit.prevent="onFormSubmitted">
        <div class="flex-1">
          <div
            v-show="focus && !message && !attachments.length && !sent"
            class="note-hint animated fadeIn absolute select-none rounded-lg bg-grey-200 px-3 py-3 italic leading-none text-grey-600"
            style=""
          >
            {{ $t('tickets.type_comment_tool_tip_this_message_is_only_visible_for_you_and_your_team') }}
          </div>
          <contenteditable
            ref="editor"
            v-model="message"
            :file-paste="true"
            :shift-enter="true"
            :multiline="false"
            class="message-editor flex-1 p-4"
            style="overflow: auto"
            :placeholder="$t('tickets.type_comment_press_enter')"
            @focus="focus = true"
            @blur="onBlur"
            @change="processDraft(true)"
            @file-paste="onFilePaste"
            @enter="onEnter"
          ></contenteditable>
        </div>
        <div class="ml-auto flex select-none">
          <div class="ml-auto flex items-center">
            <span class="mr-2 flex items-center">
              <i
                v-tooltip="{
                  placement: 'top',
                  content: $t('tickets.insert_emoji'),
                  delay: { show: 500, hide: 0 },
                }"
                class="material-icons cursor-pointer text-grey-400"
                @click="toggleEmojiPicker($event)"
              >
                insert_emoticon
              </i>
              <div
                v-if="showEmojiPicker"
                ref="emojiContainer"
                v-click-away="hideEmojipicker"
                class="emoji-container"
                style="position: absolute; bottom: 0"
              >
                <picker @insert-emoji="insertEmoji"></picker>
              </div>
            </span>
            <span class="mr-2 flex items-center">
              <i class="material-icons cursor-pointer text-grey-400" @click="toggleTag()">alternate_email</i>
            </span>
            <attachment-selector
              class="mr-2 flex items-center"
              color-class="text-grey-400"
              @attachment="addAttachment"
            ></attachment-selector>
            <span class="flex h-full flex-shrink-0 items-center justify-center">
              <i class="material-icons mr-3 cursor-pointer text-green" @click="onEnter()">arrow_upward</i>
            </span>
          </div>
        </div>
      </form>
      <transition name="slide">
        <div v-if="attachments.length > 0" class="selected-attachments flex flex-wrap py-3">
          <div v-for="(attachment, index) in attachments" :key="attachment.full_url" class="m-b-xs mx-3 text-grey-600">
            <span class="label success-outline m-r-xs">{{ attachment.extension }}</span>
            <a v-show="!attachment.uploading" class="m-r-xs" :href="attachment.full_url" target="_blank">
              <i class="fa fa-download"></i>
            </a>
            <span class="attachment-name m-r-xs whitespace-nowrap" data-hj-suppress>{{ attachment.client_name }}</span>
            <small v-if="typeof attachment.size === 'string'" class="text-muted m-r-xs whitespace-nowrap">
              {{ attachment.size }}
            </small>
            <span class="whitespace-nowrap">
              <i v-show="attachment.uploading" class="fa fa-spin fa-spinner m-r-xs"></i>
              <i
                v-show="!attachment.uploading"
                class="fa fa-remove"
                style="cursor: pointer"
                @click="removeAttachment(attachment, index)"
              ></i>
            </span>
          </div>
        </div>
      </transition>
    </div>
    <confirm-mention-unauthorized-users-modal
      @accept="confirmMentioningUserIdsWithoutAccess"
    ></confirm-mention-unauthorized-users-modal>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { mixin as VueClickAway } from 'vue3-click-away';

import eventBus from '@/eventBus';
import { useUsersStore } from '@/store/pinia';
import { escapeHtml } from '@/util/stringHelpers';

import attachmentSelector from './MultipleAttachmentSelector.vue';
import taggable from '../../util/Taggable';
import ConfirmMentionUnauthorizedUsersModal from '../ConfirmMentionUnauthorizedUsersModal';
import Contenteditable from '../ContentEditable.vue';
import Picker from '../Elements/EmojiPicker';

export default {
  name: 'TicketNoteComposer',

  components: {
    attachmentSelector,
    Picker,
    Contenteditable,
    ConfirmMentionUnauthorizedUsersModal,
  },

  mixins: [VueClickAway],
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },
    publicProvider: {
      type: String,
      default: '',
    },
    ticketId: {
      type: Number,
      default: 0,
    },
  },
  emits: ['call-parent-appendMessage-method', 'call-parent-newMessage-method'],

  data() {
    return {
      attachments: [],
      showEmojiPicker: false,
      focus: false,
      dragenter: false,
      sending: false,
      message: '',
      draftTimer: null,
      draft: {},
      pendingUploads: 0,
      tribute: {
        isActive: false,
      },
      dirty: true,
      sent: false,
    };
  },

  computed: {
    ...mapStores(useUsersStore),
  },

  unmounted() {
    clearTimeout(this.draftTimer);
  },

  beforeUnmount: function () {
    try {
      this.tribute.detach(this.$refs.editor.$refs.el);
    } catch (e) {
      console.error('Error detaching tribute', e);
    }
  },

  mounted() {
    this.handleIe11();
    this.initAttachmentDrop();
    this.restoreDraft();
    this.$nextTick(() => {
      this.initMention();
    });
  },

  methods: {
    onBlur(value) {
      this.message = value;
      this.focus = false;
      this.processDraft(true);
    },

    handleIe11() {
      const element = this.$el.querySelector('.composer-container');
      if (this.ticket == null || this.ticket.channel == null || !window.isIe11() || !element) {
        return;
      }

      element.style.height = '150px';
    },

    processDraft(instant = false) {
      if (this.message.length > 0 || this.attachments.length > 0) {
        clearTimeout(this.draftTimer);
        this.draftTimer = setTimeout(
          () => {
            axios
              .post('/api/v2/tickets/' + this.ticketId + '/me/note_draft', {
                message: this.message,
              })
              .then(() => {
                clearTimeout(this.draftTimer);
              });
          },
          instant ? 0 : 100,
        );
      } else {
        if (this.attachments.length === 0) {
          this.deleteDraft();
        }
      }
    },

    restoreDraft() {
      axios
        .get('/api/v2/tickets/' + this.ticketId + '/me/note_draft', {
          cancelToken: axiosRequestToken('ticket_note'),
        })
        .then((res) => {
          this.decorateDraft(res.data);
        })
        .catch((e) => {
          console.error('Error restoring draft', e);
        });
    },

    decorateDraft(data) {
      if (data.message != '') {
        this.message = data.message || '';
        this.onMessageUpdate();
      }

      this.draft = data;
      this.attachments = data.attachments;
    },

    deleteDraft() {
      this.message = '';
      this.attachments = [];
      this.onMessageUpdate();
      axios.delete('/api/v2/tickets/' + this.ticketId + '/me/note_draft/0');
    },

    onEnter() {
      this.send();
    },

    toggleEmojiPicker(e) {
      this.showEmojiPicker = !this.showEmojiPicker;
      if (this.showEmojiPicker) {
        this.$nextTick(() => {
          const el = this.$refs.emojiContainer;
          el.style.left = e.target.offsetLeft - 326 + 'px';
          el.style.bottom = '40px';
        });
      }
    },

    insertEmoji(emoji) {
      this.showEmojiPicker = false;
      window.insertAtCursor(this.$refs.editor.$refs.el, emoji.emoji);
      this.message = this.$refs.editor.$refs.el.innerHTML;
      window.placeCaretAtEnd(this.$refs.editor.$refs.el);
      this.$refs.editor.$refs.el.focus();
    },

    addAttachment(data) {
      const formData = new FormData();
      formData.append('file', data.obj);

      data.uploading = true;
      data.client_name = data.name;
      data.id = null;
      this.attachments.push(data);
      this.pendingUploads++;

      axios
        .post('/api/v2/tickets/' + this.ticketId + '/me/note_draft_attachments', formData)
        .then((res) => {
          data.client_name = res.data.client_name;
          data.uploading = false;
          data.full_url = res.data.full_url;
          data.id = res.data.id;
          this.pendingUploads--;
        })
        .catch(() => {
          this.attachments.splice(this.attachments.indexOf(data), 1);
          data.uploading = false;
          this.pendingUploads--;
        });
    },

    removeAttachment(attachment) {
      this.attachments.splice(this.attachments.indexOf(attachment), 1);

      axios.delete('/api/v2/tickets/' + this.ticketId + '/me/note_draft_attachments/' + attachment.id).then(() => {
        if (this.attachments.length === 0 && this.message.length === 0) {
          this.deleteDraft();
        }
      });
    },

    initAttachmentDrop() {
      const droppable = $(this.$el);
      let lastenter = null;

      droppable.on('dragenter', (event) => {
        if (isDragSourceExternalFile(event.originalEvent.dataTransfer) === false) {
          return false;
        }
        this.dragenter = true;
        lastenter = event.target;
      });
      droppable.on('dragleave', (event) => {
        if (lastenter === event.target) {
          this.dragenter = false;
        }
      });
      droppable.on('dragover', function (e) {
        e.preventDefault();
      });
      droppable.on('drop', (e) => {
        this.onImageDrop(e);
      });
    },

    onImageDrop(e) {
      if (isDragSourceExternalFile(e.originalEvent.dataTransfer) === false) {
        return false;
      }

      const imageTypes = ['image/png', 'image/gif', 'image/bmp', 'image/jpg'];

      e.preventDefault();
      e.stopPropagation();
      const files = e.originalEvent.dataTransfer.files;
      this.dragenter = false;
      for (let i = 0; i < files.length; i++) {
        if (!imageTypes.includes(files[i].type)) {
          this.addAttachment({
            name: files[i]['name'],
            obj: files[i],
            extension: getExtFromFileName(files[i]['name']),
            size: formatBytes(files[i]['size']),
          });
        }
      }
    },

    send() {
      if (this.sending || !this.isValid()) {
        return;
      }
      const mentionedUserIdsWithoutAccess = this.mentionedUserIdsWithoutAccess();
      if (mentionedUserIdsWithoutAccess.length) {
        eventBus.$emit('modals.confirm-mention.open', mentionedUserIdsWithoutAccess);
        return;
      }
      this.$nextTick(this.processSend());
      this.sent = true;
    },

    mentionedUserIdsWithoutAccess() {
      const mentionedUserIds = [];
      const mentionedUserIdsWithoutAccess = [];
      const matches = this.message.match(/@([\w-]+)/g);
      if (!matches) {
        return [];
      }
      matches.forEach((m) => {
        const userId = m.match(/[0-9]+/);
        if (userId && userId[0]) {
          mentionedUserIds.push(parseInt(userId[0]));

          if (!taggable.userIdsWithAccess.includes(parseInt(userId[0]))) {
            mentionedUserIdsWithoutAccess.push(parseInt(userId[0]));
          }
        }
      });
      return mentionedUserIdsWithoutAccess;
    },

    confirmMentioningUserIdsWithoutAccess() {
      this.tribute = taggable.reInit(this.$refs.editor.$refs.el, this.ticketId, this.usersStore.users);
      this.processSend();
    },

    processSend() {
      this.sending = true;
      this.processMessage(this.ticketId)
        .then((res) => {
          this.onMessageSent(res.data.message);
        })
        .catch(() => {
          this.sending = false;
        });
    },

    processMessage(ticket_id) {
      // Replace "<br>".
      let message = this.message.replace(/<br>/gi, '\n');
      message = message.replace(/<br \/>/gi, '\n');
      message = message.replace(/<br\/>/gi, '\n');

      // remove html-tags
      message = window.stripHtml(message);

      // replace '<' with entities, (like htmlspecialchars)
      message = escapeHtml(message);

      const formData = {
        message: message,
        attachment_ids: [],
        internal_note: true,
      };

      if (this.attachments.length > 0) {
        for (let i = 0, len = this.attachments.length; i < len; i++) {
          formData.attachment_ids.push(this.attachments[i]['id']);
        }
      }

      const originalMessage = this.message;

      this.message = '';
      this.onMessageUpdate();
      this.$refs.editor.$refs.el.focus();

      return new Promise((resolve, _reject) => {
        axios
          .post('/api/v2/tickets/' + ticket_id + '/messages', formData)
          .then((res) => {
            resolve(res);
            eventBus.$emit('user-internal-comment-on-first-ticket');
          })
          .catch(() => {
            this.message = originalMessage;
            this.sending = false;
            this.onMessageUpdate();
          });
      });
    },

    onMessageSent(message) {
      this.sending = false;
      this.attachments = [];
      this.deleteDraft();

      if (!this.publicProvider) {
        this.$emit('call-parent-appendMessage-method', message);
        this.$nextTick(() => {
          this.$emit('call-parent-newMessage-method');
        });
      }
      this.tribute.isActive = false;
    },

    onFilePaste(file) {
      this.addAttachment({
        name: file['name'],
        obj: file,
        extension: getExtFromFileName(file['name']),
        size: formatBytes(file['size']),
      });
    },

    isValid() {
      if (this.pendingUploads > 0 || this.tribute.isActive) {
        return;
      }

      return this.message.trim() != '' || this.attachments.length > 0;
    },

    hideEmojipicker() {
      this.showEmojiPicker = false;
    },

    onMessageUpdate() {
      this.$refs.editor.$refs.el.innerHTML = this.message || '';
    },

    initMention() {
      this.tribute = taggable.initUsersTaggable(this.$refs.editor.$refs.el, this.ticketId, this.usersStore.users);
      this.$refs.editor.$refs.el.addEventListener('tribute-replaced', () => {
        this.message = this.$refs.editor.$refs.el.innerHTML;
        this.$nextTick(() => {
          document
            .querySelector('.remove-prev')
            .addEventListener(
              'DOMNodeRemoved',
              (e) => e.target.previousElementSibling && e.target.previousElementSibling.remove(),
            );
        });
      });
    },

    toggleTag() {
      if (this.tribute.isActive) {
        this.tribute.isActive = false;
        if (this.$refs.editor.$refs.el.innerHTML.endsWith('@')) {
          this.$refs.editor.$refs.el.innerHTML = this.$refs.editor.$refs.el.innerHTML.substr(0, -1);
          this.message = this.$refs.editor.$refs.el.innerHTML;
        }
        document.getElementsByClassName('tribute-container')[0].style.display = 'none'; // as long as it works ;)
        return;
      }
      this.tribute.showMenuForCollection(this.$refs.editor.$refs.el);
    },
  },
};
</script>
<style scoped>
.bg-gray {
  background-color: rgba(22, 32, 42, 0.15);
}

.bg-gray-dark {
  background-color: rgba(120, 130, 140, 0.6);
}

.selected-attachments {
  border-top: 1px solid #e9e9e9;
  transition: all 250ms ease-out;
}

.slide-enter-active,
.slide-leave-active {
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  margin-top: -5rem;
}
.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  margin-top: -22px;
}

form.note {
  word-wrap: break-word;
  width: 100%;
  transition: margin-bottom 250ms ease-out;
}

.success-outline {
  background-color: transparent;
  border: 1px solid #14b29f;
  color: #14b29f;
}

.send-button {
  width: 45px;
  text-align: center;
}

.send-button:hover {
  color: #000000;
}

.send-button i {
  line-height: 36px;
  font-size: 18px;
}

body.ie .padding-fix-ie {
  padding-top: 4px;
}

.padding-fix-ie {
  padding-bottom: 5px;
}
.emoji-browser {
  height: 220px;
}

.note-hint {
  margin-top: -50px;
  animation-duration: 0.2s;
  z-index: 99999;
}

@media (max-width: 991px) {
  .note-hint {
    margin-left: 10px;
  }
}

.note-hint:after {
  content: '';
  position: absolute;
  left: 18px;
  top: 26px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid theme('colors.grey-200');
  clear: both;
}
</style>
