import { fetchIntegrations, fetchIntegration, fetchInstallations, deleteInstallation, renameInstallation } from '@/api';
import integrations from '@/api/mocks/integrations';

import type { IntegrationResponse, InstallationsResponse } from '@/api';
import type {
  Installation,
  InstalledIntegration,
  Integration,
  IntegrationFilter,
  IntegrationPayload,
  IntegrationStore,
} from '@/store/types/integrations';
import type { Commit } from 'vuex';

const state: IntegrationStore = {
  integrations: [],
  filteredIntegrations: [],
  activeItemId: 0,
  installations: [],
};

const mutations = {
  set(state: IntegrationStore, payload: IntegrationPayload) {
    state[payload.key] = payload.data;
  },

  setAllIntegrations(state: IntegrationStore, payload: Integration[]) {
    state.integrations = payload;
  },

  setFilter(state: IntegrationStore, filter: IntegrationFilter) {
    switch (filter) {
      case 'all':
        state.filteredIntegrations = state.integrations;
        break;
      case 'installed':
        state.filteredIntegrations = state.integrations.filter((integration) => integration.isInstalled);
        break;
      default:
        state.filteredIntegrations = state.integrations.filter((integration) => integration.filterType === filter);
        break;
    }
  },

  setItem(state: IntegrationStore, itemId: Integration['id']) {
    const index = state.integrations.findIndex((integration) => {
      return integration.id === itemId;
    });
    if (index > -1) {
      state.activeItemId = itemId;
    } else {
      state.activeItemId = 0;
    }
  },

  removeInstallation(
    state: IntegrationStore,
    { integration_id, installation_id }: { integration_id: Integration['id']; installation_id: Installation['id'] },
  ) {
    const index = state.integrations.findIndex((integration) => integration.id === integration_id);
    if (index > -1) {
      state.integrations[index].installations = state.integrations[index].installations.filter(
        (installation) => installation.id !== installation_id,
      );
      state.integrations[index].isInstalled = state.integrations[index].installations.length > 0;
    }
  },

  renameInstallation(
    state: IntegrationStore,
    {
      integration_id,
      installation_id,
      name,
    }: { integration_id: Integration['id']; installation_id: Installation['id']; name: string },
  ) {
    const index = state.integrations.findIndex((integration) => integration.id === integration_id);
    if (index > -1) {
      const installationIndex = state.integrations[index].installations.findIndex(
        (installation) => installation.id === installation_id,
      );
      if (installationIndex > -1) {
        state.integrations[index].installations[installationIndex].name = name;
      }
      const callableInstallationIndex = state.installations.findIndex(
        (installation) => installation.installation_id === installation_id,
      );
      if (callableInstallationIndex > -1) {
        state.installations[callableInstallationIndex].installation_name = name;
      }
    }
  },

  setIntegrationDetails(state: IntegrationStore, integration: IntegrationResponse) {
    const index = state.integrations.findIndex((_integration) => {
      return _integration.id === integration.id;
    });
    if (index > -1) {
      state.integrations[index].form_fields = integration.form_fields;
      state.integrations[index].auth_type = integration.auth_type;
      state.integrations[index].installations = integration.installations;
      state.integrations[index].isInstalled = integration.installations.length > 0;
    }
  },

  setInstallations(state: IntegrationStore, installations: Array<InstallationsResponse>) {
    state.installations = installations.map(
      ({ id, integration_service_id, name, deeplink }) =>
        ({
          ...integrations.find((integration) => integration.service_id === integration_service_id),
          installation_id: id,
          installation_name: name,
          deeplink,
        }) as InstalledIntegration,
    );
  },
};

const actions = {
  async fetchIntegrations({ commit, state }: { commit: Commit; state: IntegrationStore }) {
    if (state.integrations.length > 0) {
      return;
    }
    try {
      const { data } = await fetchIntegrations();
      const allIntegrations = [];
      for (const integration of data.data) {
        const localIntegration = integrations.find(
          (localIntegration) => localIntegration.service_id === integration.service_id,
        );
        if (localIntegration) {
          allIntegrations.push({
            ...localIntegration,
            id: integration.id,
            isInstalled: integration.has_installations,
            is_premium: integration.is_premium,
            oauth_grant_type: integration.oauth_grant_type,
          });
        }
      }
      commit('setAllIntegrations', allIntegrations);
    } catch (e) {
      console.error(e);
    }
  },

  async fetchInstallations({ commit, state }: { commit: Commit; state: IntegrationStore }) {
    if (state.installations.length > 0) {
      return;
    }
    const responses: Array<InstallationsResponse> = [];
    try {
      let page = 1;
      let hasNext = true;
      while (hasNext) {
        const { data: response } = await fetchInstallations(page);
        responses.push(...response.data);
        if (!response.links.next) {
          hasNext = false;
        }
        page++;
      }
    } catch (e) {
      console.error(e);
    }
    commit('setInstallations', responses);
  },

  async fetchIntegration({ commit }: { commit: Commit }, id: Integration['id']) {
    try {
      const { data: integration } = await fetchIntegration(id);
      commit('setIntegrationDetails', integration);
    } catch (e) {
      console.error(e);
    }
  },

  async filterIntegrations({ commit }: { commit: Commit }, filter: IntegrationFilter) {
    commit('setFilter', filter);
  },

  async setIntegrationItem({ commit }: { commit: Commit }, id: Integration['id']) {
    commit('setItem', id);
  },

  async removeInstallationItem(
    { commit }: { commit: Commit },
    { integration_id, installation_id }: { integration_id: Integration['id']; installation_id: Installation['id'] },
  ) {
    await deleteInstallation(integration_id, installation_id);
    commit('removeInstallation', { integration_id, installation_id });
  },

  async renameInstallationItem(
    { commit }: { commit: Commit },
    {
      integration_id,
      installation_id,
      name,
    }: { integration_id: Integration['id']; installation_id: Installation['id']; name: string },
  ) {
    await renameInstallation(integration_id, installation_id, name);
    commit('renameInstallation', { integration_id, installation_id, name });
  },
};

const getters = {
  getIntegrations: (state: IntegrationStore) => state.integrations,

  getAllInstallations: (state: IntegrationStore) => state.installations,

  getInstallations: (state: IntegrationStore) => (integrationId: number) =>
    state.integrations.find((integration) => integration.id === integrationId)?.installations,

  getFiltered: (state: IntegrationStore) => state.filteredIntegrations,

  getItem: (state: IntegrationStore) => {
    const index = state.integrations.findIndex((integration) => {
      return integration.id === state.activeItemId;
    });
    return state.integrations[index];
  },

  getIntegrationByServiceId: (state: IntegrationStore) => (service_id: string) => {
    return state.integrations.find((integration) => integration.service_id === service_id);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
